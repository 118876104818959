<template>
  <div class="bg-gray-100 min-h-screen">
    <!-- Navbar Component -->
    <Navbar />

    <!-- Blog Section -->
    <section class="max-w-6xl mx-auto py-10 bg-blog-background">
      <div class="text-center mb-6">
        <p class="text-p mt-2">
          Kumpulan blog informasi menarik yang telah disajikan oleh tim
        </p>
        <h1 class="text-3xl font-bold mb-2 mt-3">Blog by Eureka Bookhouse</h1>
      </div>
      <div class="mb-6 mt-4 flex justify-center">
        <select
          class="px-2 py-2 border border-white rounded-lg bg-transparent text-white focus:outline-none focus:ring-2 focus:ring-white"
          @change="handleCategoryChange"
        >
          <option value="">Semua Kategori</option>
          <option>Acara Toko</option>
          <option>Rekomendasi Buku</option>
          <option>Promosi</option>
          <option>Review Buku</option>
          <option>Trivia</option>
          <option>Fiksi</option>
          <option>Non-Fiksi</option>
          <option>Update</option>
          <option>Sahabat Eureka</option>
        </select>
      </div>
    </section>

    <!-- Pagination -->
    <section>
      <div v-if="filteredPosts.length > perPage" class="pagination">
        <button
          v-for="page in totalPages"
          :key="page"
          @click="currentPage = page"
          :class="{ active: currentPage === page }"
        >
          {{ page }}
        </button>
      </div>
    </section>

    <!-- Blog Cards -->
    <div
      v-if="filteredPosts.length === 0"
      class="text-black text-center py-10 pt-5"
    >
      Tidak ada postingan untuk kategori ini.
    </div>

    <section v-else>
      <div class="wrapper">
        <div
          class="blog-grid"
          :style="{ minHeight: filteredPosts.length < 3 ? '300px' : 'auto' }"
        >
          <div
            v-for="(post, index) in paginatedPosts"
            :key="index"
            class="card"
          >
            <div class="image-container">
              <router-link :to="`/Blog/DetailBlog/${post.id_blogs}`">
                <img
                  :src="post.image"
                  alt="post image"
                  @error="handleImageError($event, post)"
                />
              </router-link>
            </div>
            <h2 class="title">
              <router-link
                :to="`/Blog/DetailBlog/${post.id_blogs}`"
                class="stext-105 cl2 hov-cl1 trans-04"
              >
                {{ post.title }}
              </router-link>
            </h2>
            <p class="description">{{ post.description }}</p>
            <div class="card-footer">
              <div>
                <div class="meta">{{ post.date }}</div>
                <p>By Eureka | {{ post.tags }}</p>
              </div>
              <router-link :to="post.link" class="btn"
                >Selengkapnya</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from "../Nav.vue";
import axios from "axios";

export default {
  name: "blogPage",
  components: {
    Navbar,
  },
  data() {
    return {
      posts: [],
      currentPage: 1,
      perPage: 6,
      selectedCategory: "",
    };
  },
  computed: {
    filteredPosts() {
      if (!this.selectedCategory) return this.posts;
      return this.posts.filter((post) =>
        post.tags.toLowerCase().includes(this.selectedCategory.toLowerCase())
      );
    },
    paginatedPosts() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredPosts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredPosts.length / this.perPage);
    },
  },
  methods: {
    handleCategoryChange(event) {
      this.selectedCategory = event.target.value;
      this.currentPage = 1; // Reset ke halaman pertama saat kategori berubah
    },
    async fetchBlogs() {
      try {
        const response = await axios.get(
          "https://stagingapi.eurekabookhouse.com/nimda/blog"
        );
        this.posts = response.data.data.map((item) => ({
          ...item,
          image: `https://cdn.eurekabookhouse.co.id/ebh/blog/${item.image}`,
          fallback1: `https://stagingapi.eurekabookhouse.com/assets/blogs/${item.image}`,
          fallback2: `https://stagingapi.eurekabookhouse.com/assets/uplod/blogs/${item.image}`,
        }));
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    },
    handleImageError(event, post) {
      if (event.target.src === post.image) {
        event.target.src = post.fallback1;
      } else if (event.target.src === post.fallback1) {
        event.target.src = post.fallback2;
      } else {
        event.target.src =
          "https://via.placeholder.com/300x200?text=Image+Not+Available";
      }
    },
  },
  mounted() {
    this.fetchBlogs();
  },
};
</script>

<style scoped>
.bg-blog-background {
  background-image: url("../../assets/images/bg_blog.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 20px;
  color: white;
  font-weight: bold;
}
.text-p {
  color: #acacac;
}
.wrapper {
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  background: #f3f4f6;
}
.blog-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  max-width: 1200px;
  width: 100%;
}
.card {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.image-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}
.title {
  font-size: 0.95rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: start;
  margin: 10px 0 6px;
  padding: 10px;
}
.description {
  margin-top: 4px;
  color: #444;
  flex-grow: 1;
}
.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 12px;
}
.meta {
  font-size: 0.875rem;
  color: #888;
}
.btn {
  background-color: #007bff;
  color: white;
  padding: 6px 12px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.2s ease;
}
.btn:hover {
  background: #333;
}
.pagination {
  display: flex;
  justify-content: end;
  margin-top: 25px;
  gap: 12px;
  width: 82rem;
}
.pagination button {
  background: transparent;
  border: none;
  padding: 6px 12px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.pagination button.active,
.pagination button:hover {
  background: #1e1e1e;
  color: #fff;
}
select {
  transition: all 0.3s ease;
}
select:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
select option {
  background-color: #1f2937;
  color: white;
}
</style>
