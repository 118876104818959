<template>
  <div class="bg-gray-100 min-h-screen">
    <Navbar />
    <div class="container mobile-blog-rapi-atas">
      <div class="bread-crumb flex-w p-tb-18 p-lr-0-lg">
        <a
          href="https://www.eurekabookhouse.co.id/"
          class="stext-109 cl8 hov-cl1 trans-04"
        >
          Home
          <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
        </a>
        <a
          href="https://www.eurekabookhouse.co.id/blog"
          class="stext-109 cl8 hov-cl1 trans-04"
        >
          Blog
          <i class="fa fa-angle-right m-l-9 m-r-10" aria-hidden="true"></i>
        </a>
        <span class="stext-109 cl4 text-left">
          {{ blog?.title || "Judul Blog" }}
        </span>
      </div>
    </div>

    <section class="bg0 p-b-20">
      <div class="container p-lr-80-lg">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <h1 class="ltext-102 cl2 p-t-28 text-left">
              {{ blog?.title }}
            </h1>

            <div class="flex-i flex-w flex-m stext-111 cl2">
              <span>
                <span class="cl4">Oleh</span>
                {{ blog?.author?.nama_lengkap || "Eureka" }}
                <span class="cl12 m-l-4 m-r-6">|</span>
              </span>
              <span>
                {{
                  new Date(blog?.dateposted).toLocaleDateString("id-ID", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })
                }}
                <span class="cl12 m-l-4 m-r-6">|</span>
              </span>
              <span>{{ blog?.category?.title }}</span>
              <span class="cl12 m-l-4 m-r-6">|</span>
              <i class="fa fa-eye mr-1" aria-hidden="true"></i>
              {{ blog?.visits }}
            </div>

            <br />

            <img
              :src="currentImageSrc"
              @error="handleImageError"
              alt="IMG-BLOG"
              class="blog-read-pic"
            />

            <div
              class="isi_blog ctext-101 fs-18 cl2 p-b-26 text-left mt-4"
              v-html="fixHtml(blog?.content)"
            ></div>

            <div class="text-left">
              <p><strong>Tags:</strong> {{ blog?.tags }}</p>
              <p><strong>Keywords:</strong> {{ blog?.keyword }}</p>
              <p><strong>SEO URL:</strong> {{ blog?.seo }}</p>
            </div>

            <div class="media ava-bloger mt-5" v-if="blog?.author">
              <img
                :src="
                  blog?.author_image
                    ? `https://stagingapi.eurekabookhouse.com/assets/users/${blog.author_image}`
                    : 'https://www.gravatar.com/avatar?d=mp&s=200'
                "
                class="mr-3 rounded-circle img-thumbnail"
                alt="Author"
              />

              <div class="media-body text-left">
                <p class="stext-101 m-b-8">DITULIS OLEH</p>
                <h5 class="mt-0 mtext-111">{{ blog.author.nama_lengkap }}</h5>
                <p class="badge badge-primary">
                  {{ blog.author.author_level }}
                </p>
                <p class="stext-107">{{ blog.author.deskripsi }}</p>
                <p>Email: {{ blog.author.email }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Navbar from "../Nav.vue";
import axios from "axios";

export default {
  name: "DetailBlog",
  data() {
    return {
      blog: null,
      loading: true,
      imageFallbackIndex: 0,
      imageSources: [],
      currentImageSrc: "",
    };
  },
  created() {
    const blogId = this.$route.params.id_blog;
    console.log("ID Blog:", blogId);
    this.fetchBlogDetail(blogId);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async fetchBlogDetail(id) {
      try {
        console.log("Fetching blog detail for ID:", id);
        const response = await axios.get(
          `https://stagingapi.eurekabookhouse.com/nimda/blog/${id}`
        );
        this.blog = response.data.data;
        console.log("Data blog berhasil diambil:", this.blog);

        // Setup fallback image sources
        const filename = this.blog?.image;
        this.imageSources = [
          `https://cdn.eurekabookhouse.co.id/ebh/blog/${filename}`,
          `https://stagingapi.eurekabookhouse.com/assets/blogs/${filename}`,
          `https://stagingapi.eurekabookhouse.com/assets/uplod/blogs/${filename}`,
        ];
        this.currentImageSrc = this.imageSources[0];
      } catch (error) {
        console.error("Gagal ambil data blog:", error);
      }
    },
    handleImageError() {
      this.imageFallbackIndex++;
      if (this.imageFallbackIndex < this.imageSources.length) {
        this.currentImageSrc = this.imageSources[this.imageFallbackIndex];
        console.warn(
          "Gambar gagal dimuat, mencoba sumber lain:",
          this.currentImageSrc
        );
      } else {
        console.error("Semua sumber gambar gagal dimuat.");
      }
    },
    fixHtml(html) {
      return html.replace(/<img([^>]*)>/gi, "<img$1 />");
    },
  },
};
</script>

<style>
.isi_blog img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem auto;
}
</style>
